import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { InfoWindow, Text } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "info-window"
    }}>{`Info window`}</h1>
    <p>{`Popup tooltip to be used to display more information about a marker in maps and such.`}</p>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<InfoWindow width={300} ml={200} mt={100}>\n  <Text>Content that describes what is pointed at</Text>\n</InfoWindow>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      InfoWindow,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <InfoWindow width={300} ml={200} mt={100} mdxType="InfoWindow">
    <Text mdxType="Text">Content that describes what is pointed at</Text>
  </InfoWindow>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={InfoWindow} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      